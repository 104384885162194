var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('embedded-video',{attrs:{"width":"950px","backgroundImagePath":require('@/assets/il-dc/Video-Thumbnail.jpg')}},[_c('iframe',{attrs:{"src":"https://www.youtube.com/embed/hWDvrKJcNc4?autoplay=1","title":"YouTube video player","frameborder":"0","allow":"","allowfullscreen":""}})]),_c('div',{staticClass:"py-5 px-2 px-lg-16 text-center mx-auto"},[_c('h2',{staticClass:"text-md-h4 text-h5 primary--text mb-5 text-center"},[_vm._v(" The Dual Credit Advocacy Toolkit ")]),_c('p',{staticClass:"mx-auto",staticStyle:{"max-width":"950px"}},[_vm._v(" Access to dual credit courses varies across the state. While some schools have become trailblazers in expanding their dual credit programs, other schools continue to face challenges to launch or expand dual credit coursework. That's where you come in! ")]),_c('p',{staticClass:"mx-auto",staticStyle:{"max-width":"950px"}},[_vm._v(" Looking to learn more about dual credit in Illinois? Check out Stand for Children's report, "),_c('a',{attrs:{"href":"https://dualcreditequity.org/","target":"_blank"}},[_vm._v("Expanding Equity in Dual Credit")])]),_c('p',{staticClass:"mx-auto",staticStyle:{"max-width":"950px"}},[_c('a',{attrs:{"href":"https://www.youtube.com/watch?v=ASNNIKso1qQ","target":"_blank"}},[_vm._v("Watch this brief demo to familiarize yourself with the toolkit")]),(!_vm.authorized)?[_vm._v(", or click the button below to get started.")]:_vm._e()],2),_c('p',{staticClass:"mx-auto",staticStyle:{"max-width":"950px"}},[(!_vm.authorized)?_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.isMobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-6",attrs:{"color":"accent","elevation":"0","x-large":""}},'v-btn',attrs,false),on),[_vm._v(" Get Started ")])]}}],null,false,3857341200),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pb-2 px-1 pt-1 d-flex flex-column"},[_c('v-card-title',{staticClass:"d-flex"},[_c('span',{staticClass:"headline flex-fill"},[_vm._v("Let's Get Started")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"flex-fill"},[_c('v-tabs-items',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tab-item',[_c('validation-observer',{ref:"emailObserver",staticClass:"d-flex flex-fill flex-column",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitEmail($event)}}},[_c('v-card-text',[_vm._v(" Before we begin let's get your email address so you can return to where you left off the next time you visit. ")]),_c('v-card-text',{staticClass:"flex-fill"},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email*","type":"email","required":"","persistent-hint":"","error-messages":errors,"hint":" If you've been here before we'll take you back to where you left off.","disabled":_vm.loading,"loading":_vm.loading,"autofocus":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,false,839942004)})],1),_c('button',{staticClass:"d-none",attrs:{"type":"submit"}},[_vm._v("submit")])],1)],1),_c('v-tab-item',[_c('validation-observer',{ref:"userDetailObserver",staticClass:"d-flex flex-fill flex-column",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitUserDetail($event)}}},[_c('v-card-text',[_vm._v(" By providing some contact information we'll be able to create a personalized advocacy plan and save your work. ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"name":"FirstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First Name*","required":"","error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}],null,false,2171524149)})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"name":"LastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last Name*","required":"","error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,false,517885461)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"Zip","rules":"zip|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Zip*","required":"","placeholder":"00000","error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.user.zip),callback:function ($$v) {_vm.$set(_vm.user, "zip", $$v)},expression:"user.zip"}})]}}],null,false,4118979856)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Sign me up for emails"},model:{value:(_vm.user.email_optin),callback:function ($$v) {_vm.$set(_vm.user, "email_optin", $$v)},expression:"user.email_optin"}})],1)],1)],1),_c('button',{staticClass:"d-none",attrs:{"type":"submit"}},[_vm._v("submit")])],1)],1)],1)],1),(_vm.isMobile)?_c('v-card-text',[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary","block":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.next}},[_vm._v(" Next ")]),_c('v-btn',{attrs:{"outlined":"","block":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")])],1):_c('v-card-text',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel","block":_vm.isMobile,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","block":_vm.isMobile,"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.next}},[_vm._v(" Next ")])],1)],1)],1):_vm._e()],1),_c('p',{staticClass:"mt-8 mb-0"},[_vm._v(" A very special thanks to the Joyce Foundation for its generous support of our ongoing dual credit work. "),_c('a',{attrs:{"href":"https://www.joycefdn.org/","target":"_blank"}},[_c('img',{staticClass:"mx-auto d-block",attrs:{"src":require("@/assets/joyce_foundation_2.png"),"alt":"The Joyce Foundation"}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }